::-webkit-scrollbar {
    width: 5px;
    height: 3px;
}
::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 3px;
}

::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 0px;
    box-shadow: inset 0px 0px 0px 0px #f0f0f0;
}
